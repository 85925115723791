import { combineReducers } from 'redux'
import { ConfigState } from "../components/onboarding/tunnel/types"
import { configReducer } from "../components/onboarding/tunnel/reducer"
import { configSagas } from "../components/onboarding/tunnel/sagas"

import { OnboardingState } from "./onboarding/types"
import { onboardingReducer } from "./onboarding/reducer"
import { SignUpState } from "./signup/types"
import { signUpReducer } from "./signup/reducer"
import {all} from "redux-saga/effects"
import {sagas as summarySagas} from "components/onboarding/views/summary/sagas";
import {sagas as bankIdSagas} from "components/onboarding/bankID/sagas";
import {BankIdState} from "components/onboarding/bankID/types";
import {bankIdReducer} from "components/onboarding/bankID/reducer";
import {onboardingSagas} from "./onboarding/sagas";
import { authSagas } from './auth/sagas'
import { authReducer } from './auth/reducer'
import { AuthState } from './auth/types'

export interface ApplicationState {
    config: ConfigState,
    signUp: SignUpState,
    navigation: OnboardingState,           // TODO: We probably dont need this
    bankId: BankIdState
    auth: AuthState
}
export const rootReducer = combineReducers<ApplicationState>({
    config: configReducer,
    signUp: signUpReducer,
    navigation: onboardingReducer,
    bankId: bankIdReducer,
    auth: authReducer
})

export function* rootSaga() {
    yield all([
        ...configSagas,
        ...summarySagas,
        ...bankIdSagas,
        ...onboardingSagas,
        ...authSagas
    ])
}