import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { ConfigActionTypes } from './types'
import axios, {AxiosPromise, AxiosResponse} from 'axios/index';
import {FetchComponentData, fetchComponentDataSuccess, FetchData, fetchRootItemsSuccess} from "./actions";
import {fetch} from "../../../utils/axios";



function* fetchRootItems(action:FetchComponentData) {
    try {
        const payload:AxiosResponse = yield call(fetch, 'api/v1/onboarding/views', 'GET')
        yield put(fetchRootItemsSuccess( payload.data ))
    } catch (error) {
        yield put({ type: ConfigActionTypes.LOAD_CONFIG_ERROR});     // todo: Something thats more applicable on all api calls, like API_FETCH_ERROR
    }
}
function* loadComponentData(action:FetchData)  {
    try {
        const {query, endpoint} = action.payload
        const payload:AxiosResponse = yield call(fetch, action.payload.endpoint, 'GET', action.payload.query ? {q: action.payload.query} : undefined)
        yield put(fetchComponentDataSuccess( {data: payload.data.data, query, endpoint}))

    } catch (error) {
        yield put({ type: ConfigActionTypes.LOAD_CONFIG_ERROR});     // todo: Something thats more applicable on all api calls, like API_FETCH_ERROR
    }
}


export const configSagas = [
    takeLatest(ConfigActionTypes.LOAD_COMPONENT_DATA, loadComponentData),
    takeLatest(ConfigActionTypes.LOAD_COMPONENT_DATA_FROM_VALUE, loadComponentData),
    takeLatest(ConfigActionTypes.FETCH_ROOT_ITEMS, fetchRootItems),
];
