export enum SignUpActionTypes {
    SET_ANSWER = '@@onboarding/SET_ANSWER',
    SUBMIT_USER_DETAIL = '@£onboarding/SUBMIT_USER_DETAIL',
    PURGE_USER_DATA = '@£onboarding/PURGE_USER_DATA',
}

export interface SignUpState {
    formValue: {[key: string]: any}
    userDetails: {email?: string, phone?: string, swedishPersonalIdentityNumber?: string}
}

export interface formValue {
    key: string
    value: string
}

export interface SignUpAnswer {
    id: string,
    answer: string,
    path: string
}