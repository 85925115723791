import {createSelector, Selector} from "reselect";

import * as DataUtils from "../../utils/dataUtils";
import * as pathUtils from "../../utils/pathUtils"
import {
    getComponents,
    getInputTypes,
    getSignupStructure,
    getTunnelBase
} from "../../components/onboarding/tunnel/selectors";
import {ApplicationState} from "../index";
import { get, last, findIndex, memoize } from 'lodash';

import * as R from 'ramda'

import {SIGNUP_PATH, TUNNEL_PATH} from "../../config/constants";
import { getPath } from 'store/onboarding/selectors'
import {SignUpState} from "./types";




const getSignUp = (state: ApplicationState) : SignUpState => get(state, 'signUp')

/** User submitted answers during the tunnel **/
// TODO: Rename to getTunnelAnswers so that we are consistent!
const getOnboardingAnswers = createSelector<ApplicationState, SignUpState, object>( [getSignUp], (signup:SignUpState) => get(signup, 'formValue'))

const getUserDetails = (state: ApplicationState) => state.signUp.userDetails




/** Return the index for the input component matching the current URL **/
const getComponentIndexMatchingURL = createSelector( [getPath, getSignupStructure], (path:string, signupStructure: {id: string}[]) => {

    let currentComponentIndex = 0

    if (path !== '') {
        const pathComponents:Array<string> = path.split('/')
        const componentId = last(pathComponents)
        currentComponentIndex = componentId === 'signup' ? 0 : findIndex(signupStructure,(target) => {
            return target.id === componentId
        })
    }

    return currentComponentIndex < 0 ? 0 : currentComponentIndex
})

/**
 * Get the next URL after a user detail item has been stored
 * @return {string} The next URL
 */
const getNextPath = createSelector([getTunnelBase, getPath, getSignupStructure, getComponentIndexMatchingURL], (base:string, path:string, signupStructure:{id: string}[], componentIndex:number) => {
    const nextComponentIndex = typeof signupStructure[componentIndex + 1] === 'undefined' ? -1 : componentIndex + 1
    return nextComponentIndex != -1 ? `${path}/${signupStructure[nextComponentIndex].id}` : `${base}/sign`
})

/** Return an array with input components matching the current URL ***/
const getSignupComponents = createSelector( [getPath, getSignupStructure, getComponents, getInputTypes],
    (path:string, signupStructure:object[], components:object, inputTypes:object) => {
    return signupStructure.map( obj => DataUtils.getComponentConfiguration( get(obj,  'id', ''), components, inputTypes))

})

const getInitialSignupComponent = createSelector( getSignupStructure, (components:[{[id: string]: string}]) => components[0] || {'id': 404} )

/** Return the part of the current URL that contain a signup hierarchy **/
const getSignupPath = createSelector( [getPath], ( path:string) => pathUtils.cleanPath(path)(SIGNUP_PATH))

/** Return the component meta object that maps to current pathname **/
const getActiveComponent = createSelector([getPath, getSignupStructure, getComponents, getInputTypes, getComponentIndexMatchingURL],
    (path, signupStructure:object, components:object, inputTypes:object, componentIndex:number) => {

    let activeComponent = get(signupStructure, componentIndex)
    return DataUtils.getComponentConfiguration( get(activeComponent, 'id'), components, inputTypes)
})


/** Return, if set, the submitted answer from the onboarding tunnel process that corresponds to the current url **/
const getCurrentAnswer = createSelector( [ getSignUp, getPath], (signup: SignUpState, path:string):string => {

    const tunnelPath = pathUtils.cleanPath(path)(TUNNEL_PATH)   // TODO: Need to get rid of this repeating
    const parts = pathUtils.components(tunnelPath)

    return get(signup, `formValue.${last(parts)}`, '')
})


/** Retrieve the key value only of the answers given during onboarding **/
const getOnboardingAnswersValue = createSelector(getOnboardingAnswers, (answers:object) =>
    R.mapObjIndexed( (answer:{value: string}) => R.clone(answer.value), answers))


/** Get a specific tunnel answer memoized **/
const getTunnelAnswer = createSelector(getOnboardingAnswers, answers =>
    memoize(questionId => get(answers, questionId))
)


/** Get a specific user detail memoized **/
const getUserDetail = createSelector(getUserDetails, details => memoize(
        key => get(details, `${key}.value`, ''))
)






export {
    getTunnelAnswer,
    getActiveComponent,
    getNextPath,
    getUserDetails,
    getUserDetail,
    getSignUp,
    getSignupComponents,
    getSignupPath,
    getInitialSignupComponent,
    getCurrentAnswer,
    getOnboardingAnswersValue
}