import {Action, Reducer} from "redux";
import { SignUpState, SignUpActionTypes} from "./types";
import {SignUpAction} from "./actions";


const initialState  = {
    formValue: {},
    userDetails: {}
}

export const signUpReducer:Reducer <SignUpState> = (state:SignUpState = initialState, action:SignUpAction) => {

    let updatedState = state

    switch (action.type) {
        case SignUpActionTypes.SUBMIT_USER_DETAIL:
            updatedState = { userDetails: {...state.userDetails, ...action.payload}, formValue: { ...state.formValue} }
            break
        case SignUpActionTypes.SET_ANSWER:
            updatedState = { formValue: { ...state.formValue, ...action.payload}, userDetails: { ...state.userDetails, ...action.payload} }
            break
        case SignUpActionTypes.PURGE_USER_DATA:
            updatedState = { formValue: {}, userDetails: {} }
            break
    }

    return updatedState
}
