import {createSelector, Selector} from 'reselect'
import {ApplicationState} from "../../../store"
import { OnboardingState, SignupState, NavigatationState } from "./onboarding/types"
import { get, first, last, find, isUndefined } from 'lodash'
import {View, Component, DynamicObject, ConfigState} from "./types"
import * as DataUtils from "../../../utils/dataUtils"
import {getObjectById, getObjectType} from "../../../utils/dataUtils"
import {getPath, getPathTrail} from 'store/onboarding/selectors'
import {getSignUp} from "components/onboarding/selectors";
import {SIGNUP_PATH_NAME, SUMMARY_PATH, TUNNEL_PATH} from "../../../config/constants";
import {getPathConfigComponent, getTunneIntroPath, removeLeadingTrailingSlashes} from "../../../utils/pathUtils";


import * as R from 'ramda';

const getConfig = (state: ApplicationState)=> state.config

//------------------------------------------------------------------
// Components
//------------------------------------------------------------------

const getTunnelBase = createSelector([getConfig], (state: OnboardingState) => state.base)

const getComponents = createSelector([getConfig], (state: OnboardingState) => state.components)

//------------------------------------------------------------------
// Sign-Up
//------------------------------------------------------------------

const getSignupStructure: Selector<ApplicationState, object[]> = createSelector([getConfig], (state: OnboardingState) => state.signUp)


/**
 * Get the initial URL for the first component
 */
const getInitialSignUpPath: Selector<ApplicationState, string> = createSelector([getTunnelBase, getSignupStructure], (base:string, components:object[]) => {
    const componentId = R.pipe(R.head, R.prop('id'))
    return `${base}/${SIGNUP_PATH_NAME}/${componentId(components)}`
})


const getTunnelPath = createSelector([getTunnelBase], (base: string) => `${base}/`)

/** @return {object} the correct view configuration (onboarding/freemium) **/
const getViewConfig = createSelector( [getTunnelBase, getConfig], (base:string, state: OnboardingState) => {
    return get(state, base === 'bli-medlem' ? 'view' : base) // TODO: Rename the view property in the config file
})

const getInputTypes = createSelector( [getConfig], (state: OnboardingState) => state.inputTypes)

const getViewMetaData = createSelector([getConfig], (state: ConfigState) => {
    return state.viewMetaData
})

/** @return {string} The ID for the root view object */
const getViewId = createSelector( [getViewConfig], (view: View) => get(view, 'id', ''))

/** Return the object carrying user onboarding answers **/
const getOnboardingAnswers = createSelector( [getSignUp], (signup:SignupState) => signup.formValue )

/** Return the component data section from the onboarding json data **/
const getComponentData = createSelector( [getConfig], (state: OnboardingState) => state.componentData )

/** @return {object} get the component corresponding the the current path/url */
const getCurrentComponent = createSelector([getPathTrail, getComponents, getInputTypes],
    (pathTrail:string, components:Object, inputTypes:Object ) => {
    return DataUtils.getComponentConfiguration(pathTrail, components, inputTypes)
})

/** Get the view object for the specific branch from the URL **/
const getCurrentViewModelFromURL = createSelector( [getTunnelPath, getPath, getViewConfig], (tunnelPath:string, path:string, view:View) => {
    let pathComponents = DataUtils.getPathComponents(path, tunnelPath)
    let entryPoint:string = pathComponents.length ? R.head(pathComponents) : R.propOr('id', '')(view)
    return DataUtils.getObjectById(view, entryPoint)
})

const getNavigationProgress = createSelector( [getPath, getViewConfig, getCurrentViewModelFromURL], (path:string, entryView, branch) => {

    const pathComponents:string[] = DataUtils.getPathComponents(path)
    const exitPoint = last(pathComponents) as string
    const view = DataUtils.getObjectById(entryView, exitPoint)
    const viewType:string = DataUtils.getObjectType(view )
    let progress = 0;

    if (typeof view === 'undefined' || viewType === DataUtils.TYPE_SCENE || typeof get(view, 'components') !== 'undefined') {
        progress = 0
    }
    else {

        // TODO: Refactor!
        let componentParent = {}
        let counter = 1
        while (typeof componentParent.components === 'undefined' && counter < pathComponents.length) {
            componentParent = getObjectById(branch, DataUtils.getNthPathComponentFromEnd(pathComponents, counter) as string)
            ++counter
        }

        const components = get(componentParent, 'components')
        let currentIndex = DataUtils.getComponentIndex(components, exitPoint, 1)

        // We need to back to the last depth where there wasn't components to grab the ID
        if (currentIndex === -1) {
            componentParent = getObjectById(branch, DataUtils.getNthPathComponentFromEnd(pathComponents, counter - 2) as string)
            currentIndex = DataUtils.getComponentIndex(components, R.prop('id', componentParent), 1)
        }

        progress = currentIndex / parseInt(get(components,'length', 0), 10) * 100
    }

    return progress

})


/** Retrieve a list with options/suggestions for the current component **/
const getComponentOptions = createSelector( [getPathTrail, getCurrentComponent, getComponentData, getViewConfig, getViewMetaData],
    (pathTrail:string, component:Component, componentData:object, entryView:View, sceneDescriptors:object[]) => {

    let result:object[] = []
    const currentViewObject = DataUtils.getObjectById(entryView, pathTrail)
    const endpoint = get(component, 'endpoint')



    if ( get(component, 'endpoint')) {
        result = get(componentData, endpoint)
    }
    else if (get(currentViewObject, 'scenes') && typeof sceneDescriptors !== 'undefined') {




        result = Object.keys(get(currentViewObject, 'scenes')).map( (key:string) => {
            const view = sceneDescriptors.find((trg:object) => trg.viewId === key)
            return {
                id: key,
                name: get(view, 'data.name', ''),
                nameId: get(view, 'data.id')
            }
        })
    }
    return result
})


const getHieraryPath = createSelector([getPath, getCurrentViewModelFromURL], (path:string, viewModel:object) => {




    const exit = R.pipe(R.split('/'), R.last)(path)

    const currentComponent = getObjectById(viewModel, exit)




    const firstScene = R.pipe(R.replace(TUNNEL_PATH, ''), removeLeadingTrailingSlashes, R.split('/'), R.nth(2))
    const scenes = R.prop('scenes', viewModel)
    const components = R.path([firstScene(path), 'components'], scenes)
    const id = (item) => R.prop('id', item)
    const concat = R.pipe(R.map(id), R.join('/'))


})


/**
 * Selector for retrieving the next URL after a question has been answered
 * TODO: This should be refactored!
 */
const getNextPath = createSelector( [getPath, getCurrentViewModelFromURL, getOnboardingAnswers, getTunnelPath, getTunnelBase, getInitialSignUpPath], (path:string, branch:object, answers:object, tunnelPath:string, base:string, signupPath:string) => {

    const pathComponents:string[] = DataUtils.getPathComponents(path)
    const exitPoint:string = R.last(pathComponents) as string
    const answer:object = R.propOr({}, exitPoint, answers)
    const answerId:string = R.propOr('', 'id', answer)

    const requestingScene = DataUtils.getObjectById(branch, answerId) || DataUtils.getObjectById(branch, exitPoint)
    const viewType:string = DataUtils.getObjectType(requestingScene )

    let nextPath:string = ''

    if (viewType === DataUtils.TYPE_SCENE) { // The has scenes so load the id for the previous answer
        nextPath =`${path}/${answerId}`
    }
    else if (viewType === DataUtils.TYPE_COMPONENT && typeof get(requestingScene, answerId) !== "undefined") { // The component has different paths depending on the answer
        nextPath =`${path}/${get(requestingScene, answerId)}`
    }
    // Scenario 3: Current view is a component by itself so traverse back and look at the parent currying the component
    else if (viewType === DataUtils.TYPE_COMPONENT) { // The current view is a component


        //


        // TODO: This is really in a need to be refactored
        let components = R.propOr([], 'components', requestingScene) as object[]
        let componentParent = undefined
        let counter = 1
        let currentComponent = ''

        while (!components.length && counter < pathComponents.length) {
            currentComponent = DataUtils.getNthPathComponentFromEnd(pathComponents, counter) as string
            componentParent = getObjectById(branch, currentComponent)
            components = get(componentParent, 'components', [])
            ++counter
        }

        let parentName = DataUtils.getNthPathComponentFromEnd(pathComponents, counter - 2) || exitPoint
        let currentIndex = DataUtils.getComponentIndex(components, parentName)
        let nextIndex = currentIndex + 1 < components.length ? currentIndex + 1 : -1

        if (nextIndex > -1) {
            const componentId = get(components, `${nextIndex}.id`)
            nextPath = currentIndex == -1 ? `${path}/${answerId}/${componentId}`: path.replace(last(pathComponents) as string, componentId)
        }
        else {
            nextPath = base === 'testa-dik' ? signupPath : `${getPathConfigComponent(path)}/${SUMMARY_PATH}` // TODO: Move this out to the component, shouldnt be here!
        }
    }

    return nextPath
})

export {
    getConfig,
    getComponents,
    getCurrentComponent,
    getNavigationProgress,
    getSignupStructure,
    getViewConfig,
    getInputTypes,
    getOnboardingAnswers,
    getNextPath,
    getComponentData,
    getComponentOptions,
    getViewId,
    getTunnelBase,
    getHieraryPath,
    getInitialSignUpPath
}


