import { compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootReducer, rootSaga, ApplicationState } from "./store";
import thunk from "redux-thunk";
import { BANNED_PROPS } from "./config/constants";
import { clone } from "ramda";

export const saveState = (state: any) => {
  try {
    const objectsClone = clone(state);
    const filteredResults = removedBannedProperties(objectsClone, BANNED_PROPS);
    const serializedState = JSON.stringify(filteredResults);
    // localStorage.setItem('state', serializedState);
    sessionStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};

export const loadState = () => {
  try {
    // const serializedState = localStorage.getItem('state');
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const removedBannedProperties = (
  data: { [key: string]: any },
  bannedKeys: string[]
): object => {
  for (var key in data) {
    if (bannedKeys.includes(key)) {
      delete data[key]; // TODO: Is set to undefined better?
    }

    if (
      typeof data[key] == "object" &&
      data[key] !== null &&
      key !== "componentData"
    ) {
      data[key] = removedBannedProperties(data[key], bannedKeys);
    }
  }
  return data;
};

let configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  const store = createStore(
    rootReducer,
    loadState()?.store ?? {},
    composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
  );
  let prevState: ApplicationState;
  let saver: number;
  store.subscribe(() => {
    const currentState = store.getState() as ApplicationState;
    if (
      currentState?.navigation.pathname !== prevState?.navigation.pathname
    ) {
      window.clearTimeout(saver);
      saver = window.setTimeout(() => saveState({
        store: currentState
      }));
    }
    prevState = currentState;
  });
  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore;
