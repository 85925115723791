// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-articles-article-index-tsx": () => import("./../../../src/pages/articles/articleIndex.tsx" /* webpackChunkName: "component---src-pages-articles-article-index-tsx" */),
  "component---src-pages-articles-article-list-tsx": () => import("./../../../src/pages/articles/articleList.tsx" /* webpackChunkName: "component---src-pages-articles-article-list-tsx" */),
  "component---src-pages-articles-article-tsx": () => import("./../../../src/pages/articles/article.tsx" /* webpackChunkName: "component---src-pages-articles-article-tsx" */),
  "component---src-pages-bli-medlem-index-tsx": () => import("./../../../src/pages/bli-medlem/index.tsx" /* webpackChunkName: "component---src-pages-bli-medlem-index-tsx" */),
  "component---src-pages-campaign-campaign-tsx": () => import("./../../../src/pages/campaign/campaign.tsx" /* webpackChunkName: "component---src-pages-campaign-campaign-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logga-in-index-tsx": () => import("./../../../src/pages/logga-in/index.tsx" /* webpackChunkName: "component---src-pages-logga-in-index-tsx" */),
  "component---src-pages-mina-sidor-index-tsx": () => import("./../../../src/pages/mina-sidor/index.tsx" /* webpackChunkName: "component---src-pages-mina-sidor-index-tsx" */),
  "component---src-pages-personuppgiftspolicy-tsx": () => import("./../../../src/pages/personuppgiftspolicy.tsx" /* webpackChunkName: "component---src-pages-personuppgiftspolicy-tsx" */),
  "component---src-pages-sacolonesokredirect-tsx": () => import("./../../../src/pages/sacolonesokredirect.tsx" /* webpackChunkName: "component---src-pages-sacolonesokredirect-tsx" */),
  "component---src-pages-testa-dik-index-tsx": () => import("./../../../src/pages/testa-dik/index.tsx" /* webpackChunkName: "component---src-pages-testa-dik-index-tsx" */)
}

