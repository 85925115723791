import React from "react"
import { Provider } from "react-redux"
import createStore from './src/createStore';
import initializeAxiosInterceptors from './src/config/axios-interceptors';
import { globalHistory } from "@reach/router"
import {locationUpdated} from "./src/store/onboarding/actions";

const store = createStore()

initializeAxiosInterceptors();

export default ({ element }) => {
    return <Provider store={store}>{element}</Provider>
}

export const onRouteUpdate = ({ location }) => {
    store.dispatch( locationUpdated(location));
    document.dispatchEvent(new CustomEvent('gatsby_route_update', {location: location}));
}
