export enum OnboardingActionTypes {       // TODO: Rename to navigation instead of location
    LOCATION_UPDATED = '@@onboarding/LOCATION_UPDATED',
    SAVE_PATH = '@@onboarding/SAVE_PATH',
    PURGE_PATH = '@@onboarding/PURGE_PATH',
    CREATE_DIK_USER = '@@onboarding/CREATE_USER',
    CREATE_FREEMIUM_USER = '@@onboarding/CREATE_FREEMIUM_USER',
    CREATE_USER_SUCCESS = '@@onboarding/CREATE_USER_SUCCESS',
    CREATE_USER_ERROR = "@@onboarding/SET_CREATE_USER_ERROR"
}

export interface OnboardingState {
    history: string[]
    pathname?: string
    entranceFee?: number
    unionFee?: number
    akassaFee?: number
    invoiceFee?: number
    monthlySum?: number
    oneTimeSum?: number,
    dikUserCreated:boolean,
    freemiumUserCreated: boolean,
    userCreated: boolean,
    userCreationError?: {}
}

export interface PathAction {
    type: string,
    path: string
}



