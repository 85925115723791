import * as React from 'react';
import './loader.less';
import posed from "react-pose";

interface Props {
  fade: boolean
  color?: "light" | "dark"
  children?: React.ReactNode;
  style?: any
}


const Loader = (props: Props) => {
  const { fade, children, color, style } = props
  const [visible, setVisibility] = React.useState(!fade)
  const colorClass = color === "dark" ? "u-colorDark" : ""

  React.useEffect(() => {
    setTimeout(() => {
      setVisibility(fade)
    }, 500);
  }, [fade])
  return (
    <div className="u-posRelative" style={style}>
      <FadeToggle pose={!visible ? "visible" : "hidden"}>
        {children}
      </FadeToggle>
      <FadeToggle pose={["absolute", visible ? "visible" : "hidden"]}>
        <div className={`lds-ellipsis ${colorClass}`}><div></div><div></div><div></div><div></div></div>
      </FadeToggle>
    </div>

  )
}

Loader.defaultProps = {
  color: "light"
}

const FadeToggle = posed.div({
  visible: {
    opacity: 1,
    //transition: { duration: 1000 },
  },
  hidden: {
    opacity: 0,
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)'
  }
})

export default Loader