import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from 'components/button/Button';
import Loader from 'components/loader/loader';
import Done from "../../../images/Icons/done.svg";

interface Props {
  freemium?: boolean,
  auth?: boolean,
  perMonth?: string | boolean,
  oneTimeSum?: number
  needHelpWithAkassa?: boolean
  intl: any
}


const SummaryMetaBox = (props: Props) => {
  const { freemium, auth, perMonth, oneTimeSum } = props;
  const { formatMessage } = props.intl;
  const oneTimeSumHasValue = oneTimeSum && oneTimeSum > 0

  return (
    <div className="priceSummary-price">
      {freemium ?
        <>
          <h3 className="u-colorWhite">{formatMessage({ "id": "summary.testa-dik.heading" })}</h3>
          <ul className="List">
            <li className="List-item freemiumSummary-listItem">
              <Done className="u-fillBeige"/>
              <p className="u-paddingLsm u-marginVz">{formatMessage({ "id": "summary.testa-dik.value1" })}</p>
            </li>
            <li className="List-item freemiumSummary-listItem">
              <Done className="u-fillBeige"/>
              <p className="u-paddingLsm u-marginVz">{formatMessage({ "id": "summary.testa-dik.value2" })}</p>
            </li>
            <li className="List-item freemiumSummary-listItem">
              <Done className="u-fillBeige"/>
              <p className="u-paddingLsm u-marginVz">{formatMessage({ "id": "summary.testa-dik.value3" })}</p>
            </li>
          </ul>
        </> :
        <>
          {(perMonth && oneTimeSumHasValue)
            ? (
              <p style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: 20
                }}>{formatMessage({ "id": "summary.priceCard.entry-fee-student" })}
                <span style={{ fontSize: 24, color: "#fff" }}>{oneTimeSum}kr</span>
              </p>
            ) : null} 
          
          <p className="u-marginTz u-marginBlg">{formatMessage({ "id": "summary.priceCard.cost" })}</p>
          <div className="u-marginVlg u-flex u-flexDirectionRow u-flexAlignCenter">
            {
              <Loader fade={perMonth ? false : true} style={{minHeight: 90}}>
                {
                  oneTimeSum !== null && (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <h1 className="u-marginBz">
                        {perMonth ? perMonth : "\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0"}
                      </h1>
                      <div className={`priceSummary-price-info u-marginLxsm ${auth ? "bottom" : ""}`}>
                        <p className="u-marginVz">{formatMessage({ "id": "labels.currancy" })}*</p>
                        {!auth && props.needHelpWithAkassa &&
                          <p className="priceSummary-price-border u-marginVz">
                            {
                              oneTimeSumHasValue
                              ? formatMessage({ "id": "summary.priceCard.with" })
                              : formatMessage({ "id": "summary.priceCard.inkl" })
                            }
                            {props.needHelpWithAkassa && <span>{formatMessage({ "id": "summary.priceCard.akassa" })}</span>}
                          </p>
                        }
                      </div>
                    </div>
                  )
                }
              </Loader>
            }
          </div>
        </>
      }
    </div>
  )
}

export default injectIntl(SummaryMetaBox)