import { Action } from 'redux'
import { ConfigActionTypes } from './types'
import {OnboardingActionTypes} from "../../../store/onboarding/types";
import * as Redux from "redux";
import {ApplicationState} from "../../../store";
import {purgeUserData} from "../../../store/signup/actions";

export const fetchComponentData = ( payload:string ) : FetchComponentData => ({ type: ConfigActionTypes.LOAD_COMPONENT_DATA, payload })

export const fetchComponentDataSuccess = (payload: SuggestionPayload) : OnboardingAction => (
    { type: ConfigActionTypes.LOAD_COMPONENT_DATA_SUCCESS,
        payload }
)

export const fetchRootItems = () => ({type: ConfigActionTypes.FETCH_ROOT_ITEMS})
export const fetchRootItemsSuccess = (payload:object) => ({type: ConfigActionTypes.FETCH_ROOT_ITEMS_SUCCESS, payload})



export const savePath = (path:string) => ({type: OnboardingActionTypes.SAVE_PATH, payload: path})
export const purgeHistory = () => ({type: OnboardingActionTypes.PURGE_PATH })

export const purgeHistoryThunk = () => (dispatch: Redux.Dispatch, getState: () => ApplicationState) : ThunkResult<Promise<string>> =>  {
    dispatch(purgeHistory())
    return Promise.resolve()
}


export const setBase = (path:string) => ({type: ConfigActionTypes.SET_BASE, payload: path})


export interface OnboardingAction extends Action {
    payload?: object
}


// TODO: Look into and remove all uses of this. adapt to the FetchData interface beneath!
export interface FetchComponentData extends Action {
    type: string
    payload: string
}

export interface FetchData extends Action {
    type: string
    payload: PreFetchPayload
}

export interface PreFetchPayload extends Action {
    endpoint: string,
    query?: string
}

export interface FetchComponentDataSuccess extends Action {
    type: String
    payload: SuggestionPayload
}

export interface Payload {
    data: object
}

export interface SuggestionPayload {
    endpoint: string,
    query?: string,
    data: object
}

export interface ComponentDataResponse {
    endpoint: string,
    data: object
}

export type ConfigAction =  FetchComponentData | FetchComponentDataSuccess


