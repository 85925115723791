import * as React from 'react'
import { injectIntl, InjectedIntl } from 'react-intl'
import Button from 'components/button/Button'
import Done from '../../../images/Icons/done.svg'

interface Props {
  intl: InjectedIntl
  includeAkassa: boolean
  oneTimeSum?: number
  occupationTypeId: string
}

const MemberOfferSummary = (props: Props) => {
  const { oneTimeSum, occupationTypeId } = props
  const { formatMessage } = props.intl

  return (
    <>
      <div className="u-paddingTlg priceSummary-list">
        {occupationTypeId === 'student' ? (
          <>
            <h5>{formatMessage({ id: 'summary.membership.heading-student' })}</h5>
            <ul className="List">
              <li className="List-item">
                <Done className="u-fillOrange" />
                <p className="u-paddingLsm">
                  {formatMessage({ id: 'summary.membership.value1-student' })}
                </p>
              </li>
              <li className="List-item">
                <Done className="u-fillOrange" />
                <p className="u-paddingLsm">
                  {formatMessage({ id: 'summary.membership.value2-student' })}
                </p>
              </li>

              <li className="List-item">
                <Done className="u-fillOrange" />
                <p className="u-paddingLsm">
                  {formatMessage({ id: 'summary.membership.value3-student' })}
                </p>
              </li>
            </ul>
          </>
        ) : (
          <>
            <h5>{formatMessage({ id: 'summary.membership.heading' })}</h5>
            <ul className="List">
              <li className="List-item">
                <Done className="u-fillOrange" />
                <p className="u-paddingLsm">{formatMessage({ id: 'summary.membership.value1' })}</p>
              </li>
              <li className="List-item">
                <Done className="u-fillOrange" />
                <p className="u-paddingLsm">{formatMessage({ id: 'summary.membership.value2' })}</p>
              </li>
              <li className="List-item">
                <Done className="u-fillOrange" />
                <p className="u-paddingLsm">{formatMessage({ id: 'summary.membership.value3' })}</p>
              </li>
            </ul>
          </>
        )}
      </div>
      {oneTimeSum !== null && (
        <div className="priceSummary-help u-marginTsm">
          <div>
            {/* {occupationTypeId === 'student' && (
              <div className="priceSummary-help u-marginTsm">
                <h6> Just nu gratis för studenter!</h6>
                <p className="u-inline u-marginRxsm">
                  Vi bjuder på medlemsavgiften under hela studietiden – spara 100 kr. Gäller 1
                  augusti–31 oktober 2024.
                </p>
              </div>
            )} */}
            {oneTimeSum !== undefined && oneTimeSum > 0 ? (
              <React.Fragment>
                {/* <p className="u-inline u-marginRxsm">
                  <strong>{formatMessage({ id: 'summary.membership.help.text-student1' })} </strong>
                </p> */}
                {/* <br />
                <p className="u-inline u-marginRxsm">
                  {formatMessage({ id: 'summary.membership.help.text-student2' })}
                </p>
                <br />
                <p className="u-inline u-marginRxsm">
                  {formatMessage({ id: 'summary.membership.help.text-student3' })}
                </p> */}
              </React.Fragment>
            ) : (
              <>
                <p className="u-inline u-marginRxsm">
                  {formatMessage({ id: 'summary.membership.help.text3' })}
                </p>
                {/* <p className="u-marginRxsm">
                  {formatMessage({ id: 'summary.membership.help.text2' })}
                </p> */}
              </>
            )}
            {/* <Button
              className="link-blue"
              href={formatMessage({ id: 'summary.membership.help.checkbox.link' })}
              target="_blank"
            >
              {formatMessage({ id: 'summary.membership.help.toc' })}
            </Button> */}
          </div>
        </div>
      )}
    </>
  )
}

export default injectIntl(MemberOfferSummary)
