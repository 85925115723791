import { Link } from 'gatsby'
import * as React from 'react'

import './Button.less'
import LoaderEllipsis from 'components/loader/LoaderEllipsis'

interface Props {
  to?: string
  href?: string
  onClick?: () => void
  onFocus?: () => void
  onBlur?: () => void
  children?: React.ReactNode
  className?: string
  state?: any
  disabled?: boolean
  type?: string
  target?: string
  isLoading?: boolean
  loaderColor?: 'dark' | 'light'
  rel?: string
}

const Button = (props: Props) => {
  const {
    to,
    href,
    onClick,
    onBlur,
    onFocus,
    children,
    className,
    state,
    disabled,
    type = 'button',
    target,
    isLoading,
    rel,
  } = props

  const loadingClass = isLoading ? 'is-loading' : ''

  const renderLink = () => {
    if (to) {
      return (
        <Link
          to={`/${to}`}
          onBlur={onBlur && onBlur}
          onFocus={onFocus && onFocus}
          className={`${className} ${loadingClass}`}
          state={state}
        >
          {isLoading ? <LoaderEllipsis color={props.loaderColor} /> : children}
        </Link>
      )
    } else if (href) {
      return (
        <a
          href={href}
          onBlur={onBlur && onBlur}
          onFocus={onFocus && onFocus}
          className={`${className} ${loadingClass}`}
          target={target}
          rel={rel}
        >
          {isLoading ? <LoaderEllipsis color={props.loaderColor} /> : children}
        </a>
      )
    } else {
      return (
        <button
          onBlur={onBlur && onBlur}
          onFocus={onFocus && onFocus}
          className={`${className} ${loadingClass}`}
          onClick={onClick && onClick}
          disabled={disabled}
          type={type && type}
        >
          {isLoading ? <LoaderEllipsis color={props.loaderColor} /> : children}
        </button>
      )
    }
  }

  return <React.Fragment>{renderLink()}</React.Fragment>
}

export default Button
