import { Action } from 'redux'
import { SignUpActionTypes } from './types'
import * as Redux from "redux";
import {ApplicationState} from "../index";
import {getNextPath} from "./selectors";
import {DynamicObject} from "components/onboarding/tunnel/types";

export type SignupFormValue = string | number | boolean;

export type SignupFormObject = {[P: string]: SignupFormValue};

export const actionRecordSignUp = (payload: SignupFormObject) : SignUpUpdated => ({ type: SignUpActionTypes.SET_ANSWER, payload })
export const actionSubmitAnswer = (payload: Object)  => ({type: SignUpActionTypes.SUBMIT_USER_DETAIL, payload})

export const purgeUserData = ()  => ({type: SignUpActionTypes.PURGE_USER_DATA})

export const purgeUserDataThunk = () => (dispatch: Redux.Dispatch) =>  {
    dispatch(purgeUserData())
    return Promise.resolve();
}

/** Thunk Action creator for submitting a user detail input **/
export const submitUserDetail = (data: string, value:string) => (dispatch: Redux.Dispatch, getState: () => ApplicationState) : Promise<string> =>  {
    dispatch(actionSubmitAnswer({[data]: value}))
    return Promise.resolve(getNextPath(getState()) as string)
}

export interface SignUpUpdated extends Action {
    type: String,
    payload: {[p: string]: SignupFormValue}
}

export type SignUpAction = SignUpUpdated