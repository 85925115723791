import { Reducer } from "react"
import { AuthState, AuthActionTypes } from "./types"
import { AuthAction } from "./actions"

const initialState: AuthState = {
    status: "UNVERIFIED"
}

export const authReducer: Reducer<AuthState, AuthAction> = (state: AuthState = initialState, action: AuthAction) => {
    switch (action.type) {
        case AuthActionTypes.AUTH_UPDATED:
            return { ...state, ...action.payload }
        case AuthActionTypes.SIGNOUT_USER:
            return { ...state, ...action.payload }
    }

    return state;
}