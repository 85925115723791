import {View} from "components/onboarding/tunnel/types";

export enum BankIdActionTypes {
    INITIATE_AUTH = '@@bankid/auth_initiate',
    AUTH_INITIATED = '@@bankid/auth_initiated',
    FETCH_AUTH_STATUS = '@@bankid/fetch_auth_status',
    AUTH_STATUS_UPDATED = '@@bankid/auth_status_updated',
    AUTH_ERROR = '@@bankid/auth_error',
    INITIATE_SIGNING = '@@bankid/initiate',
    SIGNING_INITIATED = '@@bankid/signing_initiated',
    SIGNING_ERROR = '@@bankid/signing_error',
    FETCH_SIGN_STATUS = '@@bankid/fetch_sign_status',
    SIGN_STATUS_UPDATED = '@@bankid/sign_status_updated',
    CANCEL = '@@bankid/cancel',
    CLEAR = "@@bankid/clear",
    DISCARD_ERROR = "@@bankid/discard_error"
}

export interface BankIdAction {
    key: string,
    value: string
}

export interface BankIdError {
    type: string;
    details: string;
    displayMessage: string;
}

export type MemberStatuses = "NotMember" | "Exiting" | "Entering" | "Member"
export type MemberRoles = "Prio" | "Start" | "None"

export interface BankIdState {
    orderRef?: string,
    autoStartToken?: string,
    autoLaunch?: boolean,
    launchUrl?: string,
    startFailed?: boolean,
    endpoint?: string,
    retrySeconds?: number,
    hasError?: boolean,
    isCompleted?: boolean,
    qrCode?: string,
    isCancelled?: boolean
    statusMessage?: string
    memberStatus?: MemberStatuses
    role?: MemberRoles
    error?: BankIdError
}