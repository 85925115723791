import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { OnboardingActionTypes } from "./types";
import { AxiosResponse } from "axios/index";
import { CreateUserSuccess, setUserCreationError } from "./actions";

import { Action } from "redux";
import { OnboardingAction } from "../types";
import { fetch } from "../../utils/axios";

function createUser(endpoint: string, payload: any) {
  return fetch(endpoint, "POST", payload);
}

function* createFreemiumUser(action: OnboardingAction) {
  try {
    yield call(createUser, "api/v1/onboarding/apply/freemium", action.payload);
    yield put(CreateUserSuccess());
  } catch (error) {
    yield put(setUserCreationError(error));
  }
}

function* createDikUser(action: OnboardingAction) {
  try {
    yield call(createUser, "api/v1/onboarding/apply", action.payload);
    yield put(CreateUserSuccess());
  } catch (error) {
    yield put(setUserCreationError(error));
  }
}

export const onboardingSagas = [
  takeLatest(OnboardingActionTypes.CREATE_DIK_USER, createDikUser),
  takeLatest(OnboardingActionTypes.CREATE_FREEMIUM_USER, createFreemiumUser)
];
