
import {removeEmpty} from "./dataUtils";
import * as R from 'ramda';

/**
 * Clean a URL path from unwanted garbage
 * @param {string} path
 * @returns { (replacement: string) => string}
 */

export const cleanPath = (path:string) => {




    const slashPurged = removeLeadingTrailingSlashes(path)

    return (replacement:string) => {
        return removeLeadingTrailingSlashes(slashPurged.replace(replacement, ''))
    }
}

/**
 * Retrieve the none components part of the onboarding/freemium path
 * @param {string} path
 * @returns {string}
 */
export const getTunneIntroPath = (path:string) => {
    return `${getPathConfigComponent(path)}`

}

/** Return the head path component to be used to navigate in the correct data set **/
export const getPathConfigComponent = (path:string) => R.compose(R.head, removeEmpty, R.split('/'))(path)

/**
 * Splits a URL path to an array
 * @param {string} path
 * @returns {array}
 */
export const components = (path:string) => {
    return path.split('/').filter( (path:string) => path !== '' )
}

/**
 * Removes leading and trailing slashes
 * @param {string} path
 * @return {string}
 */
export const removeLeadingTrailingSlashes = (path:String) => path.replace(/^\//g, '').replace(/\/$/, '')