import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import axios, {AxiosPromise, AxiosResponse} from 'axios/index';
// Actions
import {fetchMonthlyFeeSuccess, SummaryActionTypes} from "components/onboarding/views/summary/actions";
import {FetchComponentData} from "components/onboarding/tunnel/actions";

function handleLoad(payload:object, endpoint:string, method:string) : AxiosPromise {
    return axios({ // TODO: Wrap this in a util? Something like API.call(endpoint, params) ???
        method: method,
        url: `/${endpoint}`,
        data: payload
    });
}

function* watchFetchMonthlyFee(action:FetchComponentData)  {
    try {
        const payload:AxiosResponse = yield call(handleLoad, {salary: action.payload.salary, occupationTypeId: action.payload.occupationTypeId}, 'api/v1/onboarding/fee/calculate', 'POST')
        yield put(fetchMonthlyFeeSuccess( payload.data.data ))

    } catch (error) {
        //yield put({ type: ConfigActionTypes.LOAD_CONFIG_ERROR});     // todo: Something thats more applicable on all api calls, like API_FETCH_ERROR
    }
}

export const sagas = [
    takeLatest(SummaryActionTypes.FETCH_MONTHLY_FEE, watchFetchMonthlyFee),
];
