import { Reducer, Action } from 'redux'
import { ConfigState, ConfigActionTypes } from './types'
import {ConfigAction, FetchComponentDataSuccess, OnboardingAction, SuggestionPayload} from "./actions"
import onboarding from '../../../data/onboarding-config.json'
import {getViewConfig} from "components/onboarding/tunnel/selectors"
import { cloneDeep, forEach, find, get, last, isUndefined, isEmpty } from 'lodash'
import {structureOptions} from "../../../utils/dataUtils";

const initialState = Object.assign({ loading: true }, onboarding)


export const configReducer:Reducer<ConfigState> = (state:ConfigState = initialState, action:Action) => {
    switch (action.type) {
        case ConfigActionTypes.LOAD_COMPONENT_DATA_SUCCESS:
            return onComponentDataLoaded(state, action)

        case ConfigActionTypes.FETCH_ROOT_ITEMS_SUCCESS:
            return onFetchRootItemsSuccess(state, action)

        case ConfigActionTypes.SET_BASE:

            return onSetBase(state, action)
    }
    return state
}

function onLoadConfig(state:ConfigState) {
    return { ...state, loading: true }
}

function onConfigLoaded(state:ConfigState, action:OnboardingAction) {
    return { ...state, loading: false, ...action.payload }
}


function onSetBase(state:ConfigState, action:{payload: string}) {
    return { ...state, base: action.payload}
}



function onComponentDataLoaded(state:ConfigState, action:FetchComponentDataSuccess) {

    const {data, endpoint, query} = action.payload
    let updatedData = undefined

    if (query && query.length) {
        const dataForCurrentKey = get(state.componentData, endpoint)
        updatedData = dataForCurrentKey ? { ...dataForCurrentKey, [query as string]: data} : { [query as string]: data}
    }
    else {
        updatedData = data
    }

    return {...state, componentData: { ...state.componentData, [endpoint] : updatedData }}
}

function onFetchRootItemsSuccess(state:ConfigState, action:{payload: {data:object}}) {
    return {...state, viewMetaData: action.payload.data}
}