import * as React from 'react'
import './loader.less'
import posed from 'react-pose'

interface Props {
  color?: 'light' | 'dark'
  style?: React.StyleHTMLAttributes<HTMLDivElement>
}

const LoaderEllipsis = (props: Props) => {
  const { color, style } = props
  const [visible, setVisibility] = React.useState(false)
  const colorClass = color === 'dark' ? 'u-colorDark' : ''

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setVisibility(true)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div className="u-posRelative" style={style}>
      <FadeToggle pose={[visible ? 'visible' : 'hidden']}>
        <div className={`lds-ellipsis lds-ellipsis--simple ${colorClass}`}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </FadeToggle>
    </div>
  )
}

LoaderEllipsis.defaultProps = {
  color: 'light',
}

const FadeToggle = posed.div({
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
})

export default LoaderEllipsis
