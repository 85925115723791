/* Global selectors */

import {createSelector, Selector} from 'reselect'
import {ApplicationState} from "../../store";
import {SignUpState} from "../../store/signup/types";

const getSignUp = (state: ApplicationState) : SignUpState => state.signUp

export {
    getSignUp
}