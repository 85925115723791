import { call, put, takeLatest } from 'redux-saga/effects'
import {fetch} from "../../utils/axios";
import { AuthActionTypes } from './types';
import { authUpdated } from './actions';
import { AxiosError } from 'axios';
import { MemberRoles } from 'components/onboarding/bankID/types';

interface AuthResponse {
    data: {
        data: {
            loggedIn: false
        } | {
            loggedIn: true
            role: MemberRoles
        }
    }
}

function fetchAuthStatus(endpoint: string) {
    return fetch(endpoint, "GET", {}, { withCredentials: true })
}

function* updateAuthStatus() {
    try {
        const response: AuthResponse = yield call(fetchAuthStatus, "api/v1/user/session")

        if(response.data.data.loggedIn) {
            yield put(authUpdated({ status: "LOGGED_IN", role: response.data.data.role }))
        } else {
            yield put(authUpdated({ status: "UNAUTHORIZED" }))
        }
    } catch (error) {
        yield put(authUpdated({ status: "UNVERIFIED" }))
    }
}

// function* fetchSessionData() {
//     try {
//         const session: object = yield call(fetchAuthStatus, "api/v1/user/session")
//         yield put(authUpdated({ status: "LOGGED_IN", session }))
//     } catch (error) {
//         const axiosError: AxiosError = error;

//         if(axiosError.code === "401") {
//             yield put(authUpdated({ status: "UNAUTHORIZED" }))
//         }
//     }
// }

function* signOutUser() {
    try {
        yield call(fetchAuthStatus, "api/v1/user/signout")
        yield put(authUpdated({ status: "UNAUTHORIZED" }))
    } catch (error) {
        const axiosError: AxiosError = error;
        yield put(authUpdated({ status: "UNAUTHORIZED" }))
    }
}

export const authSagas = [
    takeLatest(AuthActionTypes.UPDATE_AUTH, updateAuthStatus),
    takeLatest(AuthActionTypes.SIGNOUT_USER, signOutUser)
]