import { MemberRoles } from "components/onboarding/bankID/types";

export enum AuthActionTypes {
    SIGNOUT_USER = '@@auth/SIGNOUT_USER',
    UPDATE_AUTH = '@@auth/UPDATE_AUTH',
    AUTH_UPDATED = '@@auth/AUTH_UPDATED'
}

export type AuthState = {
    status: "UNAUTHORIZED" | "UNVERIFIED"
} | { status: "LOGGED_IN", role: MemberRoles }