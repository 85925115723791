import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'components/button/Button'
import { Link } from 'gatsby'
import LoaderEllipsis from 'components/loader/LoaderEllipsis'
import { successfulMembership } from '../../../utils/gtmUtils'
import QRCode from 'react-qr-code'

type Props = {
  isBankId: boolean
  statusMessage?: string
  qrCode?: string
  onClick?: (autoStart?: boolean) => () => void
  signupPath?: string
  checked?: boolean
  pendingSignature?: boolean
  abort?(): void
  disableGTMEvent: boolean
}

const SignButton = (props: Props, context: any) => {
  const { isBankId, statusMessage, qrCode, checked, pendingSignature } = props

  const [showLoader, setShowLoader] = React.useState(false)
  const [showQRCode, setShowQRCode] = React.useState(false)

  return (
    <>
      {!isBankId && (
        <div className="priceSummary-button-container">
          <div className="priceSummary-button">
            <Link
              onClick={() => {
                !props.disableGTMEvent && successfulMembership()
              }}
              className={`button button--normal orange u-sizeFull u-md-sizeFill`}
              to={`/${props.signupPath && props.signupPath}`}
              state={{ wasNavigated: true }}
            >
              {' '}
              {/* u-sizeFull u-md-sizeFill */}
              <FormattedMessage id="summary.membership.apply.member" />
            </Link>
          </div>
        </div>
      )}

      {isBankId &&
        (pendingSignature ? (
          <div className="u-textCenter u-marginVlg">
            {showLoader && <LoaderEllipsis color="dark" />}

            <p className="u-marginTsm u-marginBmd" style={{ minHeight: '1.5em' }}>
              {statusMessage}
            </p>

            {showQRCode && qrCode && (
              <div>
                <QRCode value={qrCode} size={200} />
              </div>
            )}

            <Button onClick={props.abort} className="button button--normal blue">
              Avbryt
            </Button>
          </div>
        ) : (
          <div className="priceSummary-button-container">
            <div className="priceSummary-button">
              <Button
                onClick={() => {
                  successfulMembership()
                  props.onClick?.(true)()
                  setShowLoader(true)
                  setShowQRCode(false)
                }}
                className={`button button--normal orange u-sizeFull ${!checked && 'disabled'}`}
              >
                <FormattedMessage id="summary.membership.apply.bankid.currentDevice" />
              </Button>
            </div>

            <div className="priceSummary-button">
              <Button
                onClick={() => {
                  successfulMembership()
                  props.onClick?.(false)()
                  setShowLoader(false)
                  setShowQRCode(true)
                }}
                className={`button button--normal orange u-sizeFull ${!checked && 'disabled'}`}
              >
                <FormattedMessage id="summary.membership.apply.bankid.remoteDevice" />
              </Button>
            </div>
          </div>
        ))}

      {!isBankId ? (
        <>
          {/* 
          <span
            style={{ borderBottom: "none" }}
            className="priceSummary-register u-block u-sizeFull u-marginBxsm"
          >
           <FormattedMessage id="summary.membership.apply.testa-dik.notReady" />
          </span>
          <Button
            className="priceSummary-register-link link-gray u-sizeFull u-block u-textCenter u-marginBsm"
            to="testa-dik"
          >
            <FormattedMessage id="summary.membership.apply.testa-dik.link" />
          </Button>
          */}
        </>
      ) : (
        <FormattedMessage id="summary.membership.apply.confirmation">
          {(txt: any) => (
            <span className="priceSummary-register u-block u-sizeFull u-marginBxsm">{txt}</span>
          )}
        </FormattedMessage>
      )}
    </>
  )
}

export default SignButton
