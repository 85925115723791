import { TUNNEL_PATH } from '../config/constants';
import { get, findIndex, isArray, isUndefined } from 'lodash';
import {Component} from "../components/onboarding/tunnel/types";
import {components} from "./pathUtils";
import * as R from 'ramda';

export const TYPE_COMPONENT = 'type_component'
export const TYPE_SCENE = 'type_scene'

export const getPathComponents = (path:string, tunnelPath:string = '') : string[] => {




    let kk = tunnelPath !== '' ? getDataPath(path, tunnelPath) : path

    let kkk = sanitizeSlashes(kk)
    let d = kkk.length ? split(kkk) : []
    return d
}

/** Remove empty strings from an array **/
export const removeEmpty = (items:[]) => items.filter((component:string) : boolean => component !== '')

/**
 * Return a nth component from the current URL
 * @param {array} components
 * @param {number} nth
 */
export const getNthPathComponentFromEnd = (components:string[], nth:number) => {
    const targetIndex = components.length - (1 + nth)
    return get(components, `${targetIndex}`, undefined)
}




// Remove pre and trailing slashes
export const sanitizeSlashes = (str:String) => str.replace(/^\//g, '').replace(/\/$/, '')

// Only return the actual data path steps
export const getDataPath = (str:string, replace:string) => sanitizeSlashes(str.replace(replace, ''))

export const split = (str:string):string[] => str.split(/\//g)



export const getComponentIndex = (components:object[], target:string, defaultIndex:number = 0) : number  => {
    const index = findIndex(components, { id: target })

    return defaultIndex > 0 && index > -1 ? index + defaultIndex : index

}

/**
 * Unwraps an onboarding answer and returns the actual value
 * @param {string or object}
 * @return {string}
 */
export const unwrapAnswer = (answer:string | object):string => get(answer, 'value', answer)

/**
 * Return the type for a view item
 * @param {object} view object
 * @return {string} Either TYPE_COMPONENT or TYPE_SCENE
 */
export const getObjectType = (obj:{ [key: string]: any }):string => {

    let type:string = ''

    const components = get(obj, 'components', undefined)
    const scenes = get(obj, 'scenes')

    if (!isUndefined(components) && isArray(components) || ( isUndefined(components)  && isUndefined(scenes))) {
        type = TYPE_COMPONENT
    }
    else if ( typeof obj['scenes'] !== 'undefined' ) {
        type = TYPE_SCENE
    }

    return type
}


/**
 *  Creates an object with meta data for a react component
 *
 *  @param {string} componentId
 *  @param {object} components
 *  @param {object} inputTypes
 *  @return {object} A configuration for a input component
 */
export const getComponentConfiguration = (componentId:string, components:object, inputTypes:Object):Component | undefined => {


    const component = get(components, componentId)
    const inputTypeId = get(component, 'inputType')
    const inputType = get(inputTypes, inputTypeId)

    let configuration = undefined

    if (component && inputType) {
        configuration = { ...component, ...inputType }
    }
    else if (component) {
        configuration = component
    }
    else {
        // TODO: Remove when going to production
        console.error(`Error: ${inputTypeId} is not a valid input type`)
    }

    return configuration
}

/**
 * Retrieves a view model object by ID
 * @param {object} json - the view model data
 * @param {string} id - the id for the requested object
 */
export const getObjectById = (json: {[key: string]: any}, targetId:string):object => {

    let target;

    for (let key in json) {
        if ((key === 'id' || key === 'false' || key === 'true') && json[key] === targetId) {
            target = json
            break
        }

        if (typeof json[key] === 'object') {

            let needle = getObjectById(json[key], targetId)

            if (typeof needle !== 'undefined') {
                target = needle
                break
            }
        }
    }

    return target as object
}

// TODO: Fix for when the lists had no ID, but since that's fixed we can remove this
export const structureOptions = (data: object) => {

    const keys = Object.keys(data)
    const key = R.head(keys)

    const dataHead = R.prop(key, data)
    const itemHead = R.head(dataHead)

    return typeof itemHead === 'string' ? { [key]: dataHead.map((value:string) => ({id: value, name: value}))}: data
}
