export enum ConfigActionTypes {
    FETCH_ROOT_ITEMS = '@@onboarding/FETCH_ROOT_ITEMS',
    FETCH_ROOT_ITEMS_SUCCESS = '@@onboarding/FETCH_ROOT_SUCCESS',
    LOAD_CONFIG_ERROR = '@@onboarding/LOAD_CONFIG_ERROR',
    LOAD_COMPONENT_DATA = '@@onboarding/LOAD_COMPONENT_DATA',
    LOAD_COMPONENT_DATA_FROM_VALUE = '@@onboarding/LOAD_COMPONENT_DATA_FROM_VALUE',
    LOAD_COMPONENT_DATA_SUCCESS = '@@onboarding/LOAD_COMPONENT_DATA_SUCCESS',
    SET_BASE = '@@onboarding/SET_BASE',
}


export interface ConfigState {
    componentData?: object,
    components?: Object | undefined
    errors?: string
    freemium?: View,
    inputTypes?: Object | undefined,
    loading?: boolean
    signUpStructure: object
    signUp: object[],
    viewMetaData: object[]
    view?: View | undefined,
    base: string
}

// TODO: Confusing naming, look up where this is used!
export interface ComponentData {
    key: string
    value: object
}

export interface ComponentOptionData {
    key: string,
    name: string,
    nameId: string,
    id: string,
    path: string
}

export interface Component {
    id: string,
    componentName: string,
    data?: object | undefined,
    populateDefault?: boolean,
    endpoint?: string | undefined,
    type: string,
}

export interface View {
    id: string,
    scenes: object
}

export interface DynamicObject {
    [key: string]: any
}