import { Reducer } from "redux";
import { BankIdActionTypes, BankIdState } from "./types";
import { get } from "lodash";
import { OnboardingAction } from "store/types";

const initialState: BankIdState = {
  isCompleted: false,
  isCancelled: false,
  hasError: false,
  statusMessage: "",
  memberStatus: undefined,
  error: undefined
};

export const bankIdReducer: Reducer<BankIdState, OnboardingAction> = (
  state: BankIdState = initialState,
  action: OnboardingAction
) => {
  const apiData = get(action, "payload.links.next", {});
  switch (action.type) {
    case BankIdActionTypes.AUTH_INITIATED:
      return {
        ...state,
        ...action.payload.data,
        ...apiData,
        error: undefined,
        hasError: false
      };

    case BankIdActionTypes.AUTH_STATUS_UPDATED: {
      const clearOrderRef =
        action.payload.data.isCancelled || action.payload.data.isCompleted;
      return {
        ...state,
        orderRef: clearOrderRef ? null : state.orderRef,
        autoStartToken: clearOrderRef ? null : state.autoStartToken,
        autoLaunch: clearOrderRef ? null : state.autoLaunch,
        launchUrl: clearOrderRef ? null : state.launchUrl,
        ...action.payload.data,
        error: undefined
      };
    }

    case BankIdActionTypes.AUTH_ERROR:
      return {
        ...state,
        ...action.payload.data,
        isCancelled: true,
        error: action.payload.data.error,
        hasError: true
      };

    case BankIdActionTypes.DISCARD_ERROR: {
      return { ...state, error: null };
    }

    case BankIdActionTypes.SIGNING_INITIATED:
      return {
        ...state,
        ...action.payload.data,
        ...apiData,
        error: undefined,
        hasError: false
      };

    case BankIdActionTypes.SIGN_STATUS_UPDATED:
      const clearOrderRef =
        action.payload.data.isCancelled || action.payload.data.isCompleted;
      return {
        ...state,
        orderRef: clearOrderRef ? null : state.orderRef,
        autoStartToken: clearOrderRef ? null : state.autoStartToken,
        launchUrl: clearOrderRef ? null : state.launchUrl,
        ...action.payload.data
      };

    case BankIdActionTypes.SIGNING_ERROR:
      return {
        ...state,
        ...action.payload.data,
        error: action.payload,
        hasError: true
      };

    case BankIdActionTypes.CANCEL:
      return {
        ...state,
        ...action.payload.data,
        isCancelled: true
      };

    case BankIdActionTypes.CLEAR: {
      return {
        ...state,
        orderRef: null,
        autoStartToken: null,
        launchUrl: null
      };
    }
  }
  return state;
};
