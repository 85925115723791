import { Reducer, State } from "redux";
import { OnboardingState, OnboardingActionTypes, PathAction } from "./types";
import { NavigationAction } from "./actions";
import { get } from "lodash";
import { SummaryActionTypes } from "components/onboarding/views/summary/actions";
import { OnboardingAction } from "../types";

// TODO: This really have to changed everything that has with navigation to do, or this location-stuff need to be moved out of here. Dont really think they  belong together anyway

const initialState: OnboardingState = {
  pathname: "dfsdfsdds",
  perMonth: "---",
  history: [],
  freemiumUserCreated: false,
  dikUserCreated: false,
  userCreated: false
};

export const onboardingReducer: Reducer<OnboardingState> = (
  state: OnboardingState = initialState,
  action: NavigationAction
) => {
  switch (action.type) {
    case OnboardingActionTypes.LOCATION_UPDATED:
      return onLocationUpdated(state, action);

    case SummaryActionTypes.FETCH_MONTHLY_FEE_SUCCESS:
      return onMonthlyFeeFetched(state, action);

    case SummaryActionTypes.FETCH_MONTHLY_FEE:
      return {
        ...state,
        monthlySum: null,
        entranceFee: null,
        unionFee: null,
        akassaFee: null,
        oneTimeSum: null
      };

    case OnboardingActionTypes.SAVE_PATH:
      return onSavePath(state, action);

    case OnboardingActionTypes.PURGE_PATH:
      return onPurgePath(state, action);

    case OnboardingActionTypes.CREATE_USER_SUCCESS:
      return onFreemiumUserCreated(state, action);

    case OnboardingActionTypes.CREATE_USER_ERROR:
      return { ...state, userCreationError: action.payload };
  }
  return state;
};

function onPurgePath(state: OnboardingState, action: NavigationAction) {
  return { ...state, history: [] };
}

function onSavePath(state: OnboardingState, action: NavigationAction) {
  return { ...state, history: [...state.history, action.payload] };
}

function onLocationUpdated(state: OnboardingState, action: NavigationAction) {
  return { ...state, ...action.payload };
}

function onMonthlyFeeFetched(state: OnboardingState, action: NavigationAction) {
  return { ...state, ...action.payload };
}

function onFreemiumUserCreated(
  state: OnboardingState,
  action: OnboardingAction
) {
  return { ...state, freemiumUserCreated: true, userCreated: true };
}
