import {createSelector, Selector} from "reselect";
import { get, last } from 'lodash';
import * as pathUtils from "../../utils/pathUtils"
import { ApplicationState } from "../index";
import { OnboardingState } from './types'

import {getTunneIntroPath} from "../../utils/pathUtils";

// FIXME: Rename navigationState to onboardingState

const getNavigation = (state: ApplicationState) : OnboardingState => state.navigation

/* Returns the URL for the current flow and not the onboarding prefix */
const getPath : Selector<ApplicationState, string> =
    createSelector(getNavigation, (state:OnboardingState) => get(state, 'pathname', ''))

const getPathTrail = createSelector( getPath, (path:string) => {
    return last(pathUtils.components(path))
})

const getHistory = createSelector( getNavigation, (state: OnboardingState) => state.history)

const monthlySum = createSelector( getNavigation, (state: OnboardingState) => state.monthlySum)

const oneTimeSum = createSelector( getNavigation, (state: OnboardingState) => state.oneTimeSum)

const unemploymentFundFee = createSelector( getNavigation, (state: OnboardingState) => state.akassaFee)

const entranceFee = createSelector( getNavigation, (state: OnboardingState) => state.entranceFee)

const invoiceFee = createSelector(getNavigation, (state: OnboardingState) => state.invoiceFee)

const unionFee = createSelector(getNavigation, (state: OnboardingState) => state.unionFee)

/** @return {string} The component path only when in onboarding/freemium **/
const getTunnelComponentsPath = createSelector( getPath, (path:string) => pathUtils.cleanPath(path)(getTunneIntroPath(path)))

const getTunnelHeadPath = createSelector( getPath, (path:string) => getTunneIntroPath(path))

export {
    getTunnelHeadPath,
    getTunnelComponentsPath,
    getPath,
    getPathTrail,
    monthlySum,
    oneTimeSum,
    unemploymentFundFee,
    entranceFee,
    invoiceFee,
    unionFee,
    getHistory
}

