import logError from '../logError';

declare global {
  interface Window { dataLayer?: any[]; }
}


const pushDataLayer = (event: string, eventCategory: string, eventAction: string, eventLabel: string) => {
  window.dataLayer?.push({
    'event' : event,
    'eventCategory' : eventCategory,
    'eventAction' : eventAction,
    'eventLabel' : eventLabel,
  })
}

export const sendGAEvent = (event: string, eventCategory: string, eventAction: string, eventLabel: string) => {
  console.log(event, eventCategory, eventAction, eventLabel);
  if (!event || !eventCategory || !eventAction || !eventLabel || !window) {
    return;
  }

  try {
    pushDataLayer(event, eventCategory, eventAction, eventLabel);
  } catch (err) {
    logError(err);
  }
}

export const successfulMembership = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'event':'formSubmitSuccess','form_name':'membership'});
}

export const startMembershipApplication = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({'event': 'membership_registration_started','form_name': 'membership'});
}

export const pushPageViewPreventDuplicates = () =>{
  window.dataLayer = window.dataLayer || [];
  let sendData = true;
  let nowMillis= new Date().getMilliseconds();
  if(window.lastGTMPush != null) {
      let parts = window.lastGTMPush.split('|');
      if(parts.length == 2) {
          if(parts[0]==window.location.pathname && (parseInt(parts[1]) + 1500)>nowMillis) {
              sendData = false;
          }
      }
  }
  if(sendData) {
      window.dataLayer.push({'event':'page_view'});
      window.lastGTMPush = window.location.pathname + '|' + nowMillis;
  }
}