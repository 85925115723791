import {call, put, takeLatest, select} from 'redux-saga/effects'
import {AxiosResponse, AxiosError} from 'axios/index';
import {fetch} from "../../../utils/axios";
import {BankIdActionTypes} from "components/onboarding/bankID/types";
import {signingError, signingInitiated, signStatusUpdated, authStatusUpdated, authError, authInitiated, fetchAuthStatus} from "components/onboarding/bankID/actions";
import { get } from 'lodash';
import {OnboardingAction} from "../../../store/types";
import { ApplicationState } from 'store';
import logError from '../../../logError';
import { sendGAEvent } from '../../../utils/gtmUtils';

function* putAuthError(error: any){
    const specificError = error.data && error.data.length ? error.data[0] : error;

    const payload = {
        data: {
            error: specificError
        }
    }
    yield put(authError(payload));
    sendGAEvent('GAEvent', specificError.name, BankIdActionTypes.AUTH_ERROR, specificError.message);
    return;
}

function wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function* initiateAuthentication(action:OnboardingAction)  {

    try {

        //var redirectUrl = getBankIdUrl();

         var redirectUrl = "null"; //this is correct null



      

        var postData = {...action.payload, RedirectUrl: redirectUrl};

        const payload:AxiosResponse = yield call(fetch, 'api/v1/bankid/auth/initiate', 'POST', postData)
        yield put(authInitiated(payload.data))

    } catch (error) {
        logError(error);
        yield putAuthError(error)
    }
}

function* authPolling(action: OnboardingAction) {
    try {
        if(get(action, 'payload.cancelPolling')) {
            return
        }

        const endpoint = get(action, 'payload.endpoint')
        const orderRef = get(action, 'payload.orderRef')
        const autoLaunch = get(action, 'payload.autoLaunch')
        const isSwedishPersonalIdentityNumberProvided = get(action, 'payload.isSwedishPersonalIdentityNumberProvided')

        const payload: AxiosResponse = yield call(
            fetch, 'api/v1/bankid/auth/status', 'POST',
            {orderRef, isSwedishPersonalIdentityNumberProvided, autoLaunch},
            {withCredentials: true}
        )
       
        yield put(authStatusUpdated(payload.data))
        yield wait(1000)
        const { isCompleted, isCancelled, startFailed } = yield select(({bankId} : ApplicationState) => bankId)
        
        if (isCompleted || isCancelled) {
            yield put({type: BankIdActionTypes.CLEAR})
            return
        }

        yield put(fetchAuthStatus(orderRef, endpoint, isSwedishPersonalIdentityNumberProvided, autoLaunch))
    } catch (error) {
        logError(error)
        yield putAuthError(error)
    }
}

function* initiateSigning(action:OnboardingAction)  {
    try {
        
         var redirectUrl = "null"; //this is correct null
        

        var postData = { ...action.payload, RedirectUrl: redirectUrl };


        const payload: AxiosResponse = yield call(fetch, 'api/v1/bankid/sign/initiate', 'POST', postData)
        yield put(signingInitiated(payload.data))

    } catch (error) {
        const errors = get(error, 'response.data.errors')
        sendGAEvent('GAEvent', error.name, BankIdActionTypes.SIGNING_ERROR, error.message);
        yield put(signingError(errors))
    }
}

function* signPolling(action:OnboardingAction) {
    try {
        const orderRef = get(action, 'payload.orderRef')
        const isSwedishPersonalIdentityNumberProvided = get(action, 'payload.isSwedishPersonalIdentityNumberProvided')
        const autoLaunch = get(action, 'payload.autoLaunch')

        // TODO: Add the endpoint variable after we purged the first /
        const payload:AxiosResponse = yield call(fetch, 'api/v1/bankid/sign/status', 'POST', {orderRef, isSwedishPersonalIdentityNumberProvided, autoLaunch})

        yield put(signStatusUpdated(payload.data))
        
    } catch (error) {
        // TODO: Discuss strategy here, what should we do?
        const errors = get(error, 'response.data.errors')
        logError(error);
        // sendGAEvent('GAEvent', error.name, BankIdActionTypes.SIGNING_ERROR, error.message);
        yield put(signingError(errors[0]));
    }
}

function* cancelAuth(action:OnboardingAction)  {
    try {
        yield put({ type: BankIdActionTypes.CLEAR });
        yield call(fetch, 'api/v1/bankid/cancel', 'POST', action.payload)
        //yield put(cancelBankIdProcess(payload.data))

    } catch (error) {
        logError(error);
        yield putAuthError(error)
    }
}


export const sagas = [
    takeLatest(BankIdActionTypes.INITIATE_SIGNING, initiateSigning),
    takeLatest(BankIdActionTypes.FETCH_SIGN_STATUS, signPolling),
    takeLatest(BankIdActionTypes.INITIATE_AUTH, initiateAuthentication),
    takeLatest(BankIdActionTypes.FETCH_AUTH_STATUS, authPolling),
    takeLatest(BankIdActionTypes.CANCEL, cancelAuth)
]


