import {BankIdActionTypes, BankIdError} from "components/onboarding/bankID/types";

export const initiateBankIdAuth = (payload:object) => ({ type: BankIdActionTypes.INITIATE_AUTH, payload })
export const authInitiated = (payload:object) => ({type: BankIdActionTypes.AUTH_INITIATED, payload })
export const authError = (payload:BankIdError) => ({type: BankIdActionTypes.AUTH_ERROR, payload })
export const initiateBankIdSign = (payload:object) => ({ type: BankIdActionTypes.INITIATE_SIGNING, payload })
export const signingInitiated = (payload:object) => ({type: BankIdActionTypes.SIGNING_INITIATED, payload })
export const signingError = (payload:BankIdError) => ({type: BankIdActionTypes.SIGNING_ERROR, payload })
export const cancelBankIdProcess = (orderRef:string) => ({type: BankIdActionTypes.CANCEL, payload: {orderRef}})

export const signStatusUpdated = (payload:object) => ({type: BankIdActionTypes.SIGN_STATUS_UPDATED, payload})
export const authStatusUpdated = (payload:object) => ({type: BankIdActionTypes.AUTH_STATUS_UPDATED, payload})

export const fetchSignStatus =  (orderRef:string, endpoint:string, isSwedishPersonalIdentityNumberProvided:boolean, autoLaunch: boolean) =>
    ({type: BankIdActionTypes.FETCH_SIGN_STATUS, payload: {orderRef, endpoint, isSwedishPersonalIdentityNumberProvided, autoLaunch}})

export const fetchAuthStatus =  (orderRef:string, endpoint:string, isSwedishPersonalIdentityNumberProvided:boolean, autoLaunch: boolean, cancelPolling?: boolean) =>
    ({type: BankIdActionTypes.FETCH_AUTH_STATUS, payload: {orderRef, endpoint, isSwedishPersonalIdentityNumberProvided, autoLaunch, cancelPolling}})