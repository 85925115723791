import { Action } from "redux";
import { OnboardingActionTypes } from "./types";
import { Payload } from "../../components/onboarding/tunnel/actions";
import * as Redux from "redux";
import { ApplicationState } from "../index";
import { actionRecordSignUp, SignupFormValue } from "../signup/actions";
import { getNextPath } from "../../components/onboarding/tunnel/selectors";
import { OnboardingAction } from "../types";
import { SummaryStates } from "components/onboarding/Summary";
import { ThunkAction } from "redux-thunk";

export type CreateUserInput = {
  swedishPersonalIdentityNumber: string;
  email: string;
  phone: string;
  occupationTypeId: string;
  industry?: string;
  salary?: number;
  workplace?: string;
  jobTitle?: string;
  program?: string;
  specialization?: string;
  institution?: string;
  term?: string;
  needHelpWithAkassa?: string;
  akassa?: boolean;
  addAkassa?: string;
  addHelpService?: string;
  interestCodes?: string[];
  completedOrderRef?: string;
};

//This is the mess that's saved in redux and needs to be cleaned before sent to server.
export type UnformattedUserInput = {
  [P in keyof CreateUserInput]:
    | undefined
    | CreateUserInput[P]
    | { value: CreateUserInput[P] };
};

export const createUser = (usertype: string, payload: UnformattedUserInput) => {
  const type =
    usertype === SummaryStates.SIGN_MEMBERSHIP
      ? OnboardingActionTypes.CREATE_DIK_USER
      : usertype === SummaryStates.SIGN_FREEMIUM
      ? OnboardingActionTypes.CREATE_FREEMIUM_USER
      : "";

  //The redux hell that necessitates this function needs to go
  //Anyways, this makes flattens any keys that are sent as {value: ACTUAL_INTERESTING_VALUE} to just ACTUAL_INTERESTING_VALUE
  //Much better would be to have the values saved this way in redux from the get go instead of hippsomhapp, 
  // but touching the untyped code that handles these values will require all of the hours. 
  const allCreateUserKeys: (keyof CreateUserInput)[] = [
    "swedishPersonalIdentityNumber",
    "email",
    "phone",
    "occupationTypeId",
    "industry",
    "salary",
    "workplace",
    "jobTitle",
    "program",
    "specialization",
    "institution",
    "term",
    "needHelpWithAkassa",
    "akassa",
    "addAkassa",
    "interestCodes",
    "completedOrderRef"
  ];
  const formattedPayload = allCreateUserKeys.reduce(
    (acc, key) => {
      const inputValue = payload[key];
      if (inputValue != null) {
        //@ts-ignore: Fix redux and remove this method.
        acc[key] =
          typeof inputValue === "object" && "value" in inputValue
            ? inputValue.value
            : inputValue;
      }
      return acc;
    },
    {} as CreateUserInput
  );

  return { type, payload: formattedPayload };
};

export const CreateUserSuccess = () => ({
  type: OnboardingActionTypes.CREATE_USER_SUCCESS
});

export const locationUpdated = (payload: Payload): LocationUpdated => ({
  type: OnboardingActionTypes.LOCATION_UPDATED,
  payload
});

export const setAnswerActionCreator = (key: string, value: SignupFormValue) => (
  dispatch: Redux.Dispatch,
  getState: () => ApplicationState
) => {
  dispatch(actionRecordSignUp({ [key]: value }));
  return Promise.resolve((getNextPath(getState()) as string) || "");
};

export const setUserCreationError = (error?: {}) => ({
  type: OnboardingActionTypes.CREATE_USER_ERROR,
  payload: error
})

export interface LocationUpdated extends Action {
  type: String;
  payload: object;
}

export type NavigationAction = LocationUpdated;
