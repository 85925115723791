export const API_BASE_URL = DEFINE_API_BASE_URL || process.env.API_BASE_URL
export const TUNNEL_PATH = 'bli-medlem/'
export const SIGNUP_PATH = 'bli-medlem/signup'

export const SUMMARY_PATH = 'summary'

export const SIGNUP_PATH_NAME = 'signup'

// Properties we dont want saved in the users local storage
export const BANNED_PROPS = ['userCreated', 'config', 'signup', 'bankId']

export const KUNDO_URL = 'https://kundo.se/org/dik/'
export const MAGASIN_K_URL = 'https://magasink.se/'
export const JOBSITE = 'https://jobb.dik.se/'

export const CONTACT_DIK_DIREKT_LINK = `mailto:fraga@dik.se?subject=${encodeURIComponent(
  'DIK Direkt',
)}`
