import React, {SyntheticEvent} from 'react';
import "./checkbox.less";
import {useState} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl'

interface Props {
    labelId: string,
    labelUrl?: string,
    onCheck: (checked:boolean) => void,
    help?: boolean
    customContent?: JSX.Element
}

const Checkbox = (props: Props) => {
    const { help } = props
    const [isChecked, setChecked] = useState(help ? true : false)
    const { formatMessage } = props.intl;

    const updateCheckbox = (e:SyntheticEvent) => {
        const checkboxState = !isChecked
        setChecked(checkboxState)

        if (typeof props.onCheck !== 'undefined') {
            props.onCheck(checkboxState)
        }
    }

    return (
        <label className="checkboxContainer">

            {
                props.labelUrl && !props.customContent &&
                <>
                    <span>{formatMessage({ "id": `${props.labelId}.accept` })}</span>
                    <a className="Link" target="_blank" href={formatMessage({ "id": props.labelUrl })}>
                        {formatMessage({ "id": `${props.labelId}.terms` })}
                    </a>
                </>
            }

            {
                !props.labelUrl && !props.customContent && <FormattedMessage id={`${props.labelId}`} />

            }

            {
                props.customContent && props.customContent
            }

            <input type="checkbox"
                   checked={isChecked}
                   onChange={updateCheckbox}
            />
            <span className="checkmark"></span>
        </label>
    )
}

export default injectIntl(Checkbox)