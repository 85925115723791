/**
 * This is a context for interest codes in onboarding, it exists to avoid having to modify the brittle rocket ship that is the rest of the transaction.
 * If you ever find yourself in a position of uncomplicating/unreduxing/unjsoning the onboarding app. Please feel free to rename this to OnboardingContext and add the rest of the onboarding to this.
 */

import React, {
  PropsWithChildren,
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo
} from "react";
import axiosFetch from "axios";

export type InterestCode = {
  id: string;
  name: string;
  code: string;
  selected: boolean;
};

const InterestCodeContext = createContext({
  interestCodes: [] as InterestCode[],
  toggleInterestCodeSelection(code: InterestCode) {}
});

export const InterestCodeProvider = (props: PropsWithChildren<{}>) => {
  const [interestCodes, setInterestCodes] = useState<
    Omit<InterestCode, "selected">[]
  >([]);
  useEffect(() => {
    axiosFetch("/api/v1/interest-code").then(res => {
      setInterestCodes(res.data.data)
    });
  }, []);

  const [activeInterestCodes, setActiveInterestCodes] = useState<string[]>([]);

  const toggleInterestCodeSelection = useCallback(
    (interestCode: InterestCode) => {
      const prevIndex = activeInterestCodes.indexOf(interestCode.id);
      if (prevIndex != -1) {
        const arrayCopy = [...activeInterestCodes];
        arrayCopy.splice(prevIndex, 1);
        setActiveInterestCodes(arrayCopy);
      } else {
        setActiveInterestCodes([...activeInterestCodes, interestCode.id]);
      }
    },
    [activeInterestCodes, setActiveInterestCodes]
  );

  const context = useMemo(
    () => ({
      interestCodes: interestCodes.map(code => ({
        ...code,
        selected: activeInterestCodes.indexOf(code.id) != -1
      })),
      toggleInterestCodeSelection
    }),
    [interestCodes, activeInterestCodes, toggleInterestCodeSelection]
  );

  return (
    <InterestCodeContext.Provider value={context}>
      {props.children}
    </InterestCodeContext.Provider>
  );
};

export default InterestCodeContext;
