import React, { useEffect, useCallback, useState } from "react";
import "./Modal.less";
import posed, { PoseGroup } from "react-pose";
import Close from "../../images/Icons/close.svg";
import Authorize from "components/Mypages/Authorize";

interface Props {
  keyProp: string;
  onClose?: () => void;
  children: React.ReactNode;
  cookie?: boolean;
  expanded?: boolean;
  closeButton?: boolean;
  closeOnEsc?: boolean;
  onExpand?: () => void;
  fadeOut?: boolean;
}

const Modal = (props: Props) => {
  const onKeyUp = (event: any) => {
    if (event.keyCode === 27) {
      props.onClose && props.onClose();
    }
  };

  const preventCloseBubbling = useCallback((event: React.SyntheticEvent) => {
    event.stopPropagation();
  }, []);

  const [screenWidth, setScreenWidth] = useState(767);

  useEffect(() => {
    if (props.closeOnEsc) {
      window.addEventListener("keyup", onKeyUp, false);

      return function cleanup() {
        window.removeEventListener("keyup", onKeyUp, false);
      };
    }

    if (typeof window !== `undefined`) {
      setScreenWidth(window.innerWidth);
    }
  }, []);

  return (
    <div
      className={`modal ${props.fadeOut ? "animate-fade-out" : ""}`}
      onClick={!props.cookie ? props.onClose : undefined}
    >
      <PoseGroup animateOnMount>
        <ModalContainer
          onClick={preventCloseBubbling}
          pose={props.expanded ? "expanded" : "normal"}
          className={`modal-container ${props.cookie ? "cookie" : ""} ${props.expanded ? "modal-expanded" : ''}`}
          key={`modal_${props.keyProp}`}
        >
          {props.closeButton && (
            <button
              className="button button--closeModal"
              onClick={props.onClose}
            >
              <Close />
            </button>
          )}
          <div className="modal-content">
            {props.children}
            {props.cookie && (
              <div className={`u-marginTxsm u-marginBz ${screenWidth >= 544 ? "u-flex" : ""}`}>
                <button
                  className="button button--normal blue u-block"
                  onClick={props.onClose}
                >
                  Jag samtycker och vill gå vidare
                </button>
                {!props.expanded && screenWidth >= 767 && props.onExpand ? (
                  <button
                    className="button button-border button--normal blue u-marginLsm"
                    onClick={props.onExpand}
                  >
                    Läs mer
                  </button>
                ) : !props.expanded ? (
                  <a className="Link u-inlineBlock u-marginTsm" onClick={props.onExpand}>Läs mer</a>
                ) : (null)}
              </div>
            )}  
          </div>
        </ModalContainer>
      </PoseGroup>
    </div>
  );
};

// const ModalContainer = posed.div({
//   before: { opacity: 0 },
//   open: { opacity: 1 },
//   closed: { opacity: 0 }
// });

const transition = {
  duration: 200,
  ease: "easeOut"
}

const ModalContainer = posed.div({
  before: { opacity: 0, height: "auto" },
  enter: {
    opacity: 1,
    delay: 50,
    transition: { duration: 600, ease: "easeIn" }
  },
  exit: {
    opacity: 0,
    transition: { duration: 200, ease: "easeOut" }
  },
  activeCard: {
    backgroundColor: "#fff"
  },
  // closed: {
  //   display: "none"
  // },
  normal: {
    transition
  },
  expanded: {
    height: "auto",
    width: "100%",
    transition
  }
});

export default Modal;
