import React, { Children } from 'react'
import { connect } from 'react-redux'
import * as Redux from 'redux'
import { ApplicationState } from 'store'
import { AuthState, AuthActionTypes } from 'store/auth/types'

interface StateProps {
  auth: AuthState
}

interface DispatchProps {
  getAuthDetails: () => void
}

type Props = StateProps &
  DispatchProps & {
    children: React.ReactNode
  }

class App extends React.Component<Props> {
  refreshInterval: any
  componentDidMount() {
    this.props.getAuthDetails()

    this.setRefresh()
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.auth.status !== prevProps.auth.status) {
      this.setRefresh()
    }
  }

  setRefresh() {
    clearInterval(this.refreshInterval)
    if (this.props.auth.status === 'LOGGED_IN') {
      this.refreshInterval = setInterval(() => {
        this.props.getAuthDetails()
      }, 60 * 1000)
    }
  }

  render() {
    return this.props.children
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
  return { auth: state.auth }
}

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  getAuthDetails: () => dispatch({ type: AuthActionTypes.UPDATE_AUTH }),
})

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(App)
