import axios from 'axios'
import {AxiosResponse, AxiosRequestConfig, AxiosError} from "axios"
import { API_BASE_URL } from './constants';
import { get } from 'lodash'
axios.defaults.timeout = 50000
axios.defaults.baseURL =  API_BASE_URL

axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
const initializeAxiosInterceptors = () => {

    const onRequestSuccess = (config : AxiosRequestConfig)  => {
        config.headers['Accept-language'] = 'sv-SE'


        return config
    };




    const onResponseSuccess = (response : AxiosResponse) => response

    const onResponseError = (err:AxiosError) => {
        
        const status = get(err, "response.status");

        if (status === 403 || status === 401) {
            // unauthenticated?
            // Redirect to 403 or 401 ?
            console.log('unauth')
        }
        return Promise.reject(err)
    };

    axios.interceptors.request.use(onRequestSuccess)
    axios.interceptors.response.use(onResponseSuccess, onResponseError)
};

export default initializeAxiosInterceptors;